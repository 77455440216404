.home {
    width: 100%;
    align-items: center;
    color: #022140;
  }
  
  .about {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #022140;
    color: #FEFFFF;
  }
  
  .about h2 {
    font-family: 'Carter One', cursive;
    font-size: 4rem;
    color: #FAED26;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  .about img {
    width: 150px;
    border-radius: 100%;
    border: 3px solid #FEFFFF;
    box-shadow: 0px 3px 15px rgb(0 0 0 / 20%);
  }
  
  .about .prompt {
    width: 40%;
    font-size: 30px;
  }
  .prompt svg {
    font-size: 60px;
    margin: 5px;
    color: white;
  }
  .prompt {
    margin-bottom: 2rem;
  }
  
  .skills {
    font-size: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .skills h3 {
    font-family: 'Carter One', cursive;
    color: #022140;
  }
  
  .skills .list {
    list-style: none;
    width: 60%;
  }
  
  .list span {
    font-size: 20px;
  }

  .item {
    padding-bottom: 3rem;
  }
  
  @media only screen and (max-width: 600px) {
    .about h2 {
      font-size: 4rem;
      margin-top: 10rem;
    }
  
    .about .prompt {
      margin-top: 10px;
      font-size: 2.5rem;
      width: 80%;
    }
  
    .skills {
      text-align: center;
    }
    .list {
      padding: 0;
    }
  
    .skills h3 {
      font-size: 30px;
    }
  }