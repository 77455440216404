@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Carter+One&display=swap');

.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: 'Didact Gothic', sans-serif;
  background-color: #F0F0F0;
}

body {
  padding: 0;
  margin: 0;
}