.not-found {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 75vh;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  color: #FFFFFF;
  background-color: rgba(2, 33, 64, 0.85);
}