.footer {
    width: 100%;
    height: 200px;
    background: #022140;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
  }
  
  .socialMedia svg {
    color: #A8D0E6;
    margin: 7px;
    font-size: 1.7rem;
    cursor: pointer;
  }
  
  .footer p {
    color: #A8D0E6;
  }
  
  @media only screen and (max-width: 600px) {
    .footer svg {
      font-size: 2rem;
    }
  }