.experience {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.experience > h3 {
    font-family: 'Carter One', cursive;
    color: #022140;
    display: block;
    font-size: 2.5rem;
    font-weight: bold;
  }