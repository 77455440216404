.project {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 4rem;
  }
  
  .project h1 {
    margin-top: 50px;
    font-family: 'Carter One', cursive;
    color: #022140;
    display: block;
    font-size: 2.5rem;
    font-weight: bold;
  }
  .project img {
    width: 700px;
    border-radius: 10px;
    box-shadow: 0px 3px 15px rgb(0 0 0 / 20%);
  }
  
  .project p {
    font-size: 1.7rem;
    color: #022140;
    width: 60%;
  }
  
  .project svg {
    font-size: 60px;
    color: #3e497a;
  }

  .project .desc {
    padding-top: 2rem;
    font-size: 1.4rem;
  }

  @media only screen and (max-width: 600px) {
    .project img {
      width: 80%;
    }
  }